<template>
    <!-- 资料下载 -->
    <div :style="{'--themecolor':themecolors}">
        <div class="fileBox" id="scroll">
            <div class="fileConter">
                <van-search class="search" shape="round" v-model="keywords" @input="show_document()" placeholder="请输入文档名称查询" />
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <van-empty :image="require('@/assets/null.svg')" v-else-if="filedata.length == 0"/>
                <div v-else>
                    <ul v-for="(item,index) in filedata" :key="index">
                        <div class="conbox">
                            <div class="conleft"> <img :src="item.cover_url"></div>
                            <div class="conright">
                                <p class="file_title">{{item.file_title}}</p>
                                <div class="bottomBox">
                                    <div class="rightBot">
                                        <div class="time">{{item.created_at}}</div>
                                        <div class="rigbto">
                                            <span style="color: #888888;min-width:.7rem;">{{item.size}}</span>
                                            <span :style="{color:themecolors,'margin-left':'8px'}">{{item.format}}</span>
                                            <span style="margin-left:20px;color: #888888;" v-if="downloads"><img src="@/assets/otherTU/download.svg" alt="" > {{item.downloads}}</span>
                                        </div>
                                    </div>
                                    <div class="leftBot" @click="fileLink(item,1)"  v-if="download">
                                        <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                        <a download="w3logo">
                                            <span>下载</span>
                                        </a>
                                        
                                    </div>
                                    <div class="leftBot" @click="fileLink(item,2)"  v-if="preview">
                                        <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                        <a download="w3logo" >
                                            <span>预览</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="iframe_box" v-if="previewUrl">
                <div class="cross" @click="crossShow" :style="{background:themecolor}"><van-icon name="cross" /></div>
                <div class="loading_box" v-if="iframe_Loading && previewUrl">
                    <van-loading :color="themecolors" :text-color="themecolors"/>
                </div>
                <iframe id="ifra" :src="previewUrl" width="100%" height="100%" border="0" style="border: none;" />
            </div>
            
            <Support></Support>
        </div>
        <sideBar v-show="!previewUrl"></sideBar>
    </div>
    </template>
    <script>
    import sideBar from '@/components/sidebar';
    import Support from '@/components/support';
    import { mapState } from 'vuex'
    import { Toast } from 'vant';
    export default {
        components:{
            sideBar,
            Support
        },
        data(){
            return{
                filedata:[],
                Loading:false,
                keywords:"",
                preview:0,//预览开关
                downloads:0,//下载数量开关
                download:0,//下载开关
                previewUrl:"",
                iframe_Loading:true,
            }
        },
        watch:{
            previewUrl(val){
                if(val){
                    let that=this
                    this.$nextTick(()=>{
                         const iframe = document.querySelector('#ifra')
                        if (iframe.attachEvent) {
                            iframe.attachEvent('onload', function () {
                                that.iframe_Loading=false
                            })
                        } else {
                            iframe.onload = function () {
                                that.iframe_Loading=false
                            }
                        }
                    })
                } 
            }
        },
        created(){
            this.params = this.$route.query;
        },
        mounted(){
            this.front_end_file_download_configuration();
            this.show_document();
        },
        computed: {
            ...mapState(['themecolors','themecolor'])
        },
        methods:{
            crossShow(){
                this.previewUrl=""
                this.iframe_Loading=true
            },
            front_end_file_download_configuration(){
                 this.$store.dispatch("front_end_file_download_configuration",{
                    site_id:this.params.site_id,
                }).then(res=>{
                    if(res.data.code == 200){
                        this.preview=res.data.data.preview
                        this.downloads=res.data.data.downloads
                        this.download=res.data.data.download
                    }
                })
            },
            show_document(){
                this.Loading = true
                this.$store.dispatch("show_document",{
                    site_id:this.params.site_id,
                    module_id:this.params.module_id,
                    // status:0,
                    keywords:this.keywords,
                }).then(res=>{
                    this.Loading = false
                    if(res.data.code == 200){
                        document.title = res.data.data.module_title ? res.data.data.module_title : '轻微站';
                        this.filedata = res.data.data.module_content;
                    }
                })
            },
            //下载量接口
            fileLink(item,type){
                if(type==1){
                     if ((navigator.userAgent.indexOf('MSIE') >= 0) && (navigator.userAgent.indexOf('Opera') < 0)) {
                        var fileURL = window.open(item.file_url, "_blank", "height=0,width=0,toolbar=no,menubar=no,scrollbars=no,resizable=on,location=no,status=no");
                        fileURL.document.execCommand("SaveAs");
                        fileURL.window.close();
                        fileURL.close();
                     }else if (window.navigator.msSaveBlob) {
                        window.open(item.file_url);
                    }else{
                         let a = document.createElement('a')  // 创建a标签
                        let url = item.file_url
                        fetch(url).then(res =>res.blob()).then(blob => {           
                            a.href = URL.createObjectURL(blob)                //blob地址
                            a.download = item.file_title                                   // 下载文件的名字
                            a.click()
                        })
                    }
                   
                }else{
                    var file = item.file_url;
                    var xurl = "https://view.xdocin.com/view?src=";
                    xurl += encodeURIComponent(file);
                    this.previewUrl=xurl;
                    // window.location.href=xurl;
                }
                
            }
        }
    }
    </script>
    <style scoped lang="less">
        /deep/.van-empty,/deep/.van-loading{
            position: absolute;
            top: 28%;
            left:50%;
            transform:translateX(-50%);
        }
        /deep/.van-empty{
            width: 100%;
            .van-empty__image{
                width: 300px;
                height: 300px;
            }
        }
        /deep/.van-loading {
            top: 50%;
        }
        @Color:var(--themecolor);
        .fileBox::-webkit-scrollbar{
            display: none;
        }
        .fileBox{
            width: 100%;
            height: 100vh;
            overflow: scroll;
            .fileConter{
                width: 100%;
                min-height: 95%;
                .search{
                    position: sticky;
                    top:0;
                }
                ul{
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    .conbox{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #EBEDF0;
                        padding: .1rem;
                        .conleft{
                            width: .6rem;
                            height: .6rem;
                            padding: 10px;
                            display: flex;
                            align-items: center; /*垂直居中*/
                            justify-content: center; /*水平居中*/
                            background:#FAFAFA;
                            img{
                                width: .3rem;
                            }
                        }
                        .conright{
                            flex: 1;
                            padding:.05rem .1rem;
                            .file_title{
                                margin-bottom: 5px;
                            }
                            p{
                                font-size: .14rem;
                                color: #000000;
                                display:-webkit-box;
                                overflow: hidden;
                                word-break:break-all;
                                width: 100%;
                               -webkit-box-orient: vertical;
                               -webkit-line-clamp: 2;
                               line-height: .2rem;
                            }
                            .bottomBox{
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: .1rem;
                            .rightBot{
                                width: 100%;
                                height: 100%;
                                width: 70%;
                                line-height: .14rem;
                                span{
                                    min-width: .3rem;
                                    display: inline-block;
                                }
                                .time{
                                    color: #888888;
                                }
                                img{
                                    width: .12rem;
                                }
                            }
                            .leftBot{
                                width: .5rem;
                                height: .3rem;
                                border:1px solid @Color;
                                border-radius: 3px;
                                &:last-child{
                                    margin-left: .1rem;
                                }
                                span{
                                    text-align: center;
                                    line-height: .3rem;
                                    color: @Color;
                                    display: block;
                                    font-weight: bold;
                                }                            
                            }
                            }
                        }
                    }
                }
            }
        }
        .iframe_box{
            background: #000;
             height:100%;
             width:100%;
             position: absolute;
             top: 0;
             left: 0;
             .cross{
                position: absolute;
                right: 0.15rem;
                top: 0.1rem;
                font-size: .2rem;
                background: rgba(133, 133, 133, 0.3);
                color: #fff;
                width: 0.35rem;
                height: 0.35rem;
                text-align: center;
                line-height: .35rem;
                border-radius:.35rem ;
             }
             .loading_box{
                width: 100%;
                height: 100%;
                background: #000;
             }
        }
    </style>